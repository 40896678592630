import clsx from 'clsx'
import { FC } from 'react'

import LogoIcon from '@/components/LogoIcon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './ContrastBgSide.module.scss'

export const ContrastBgSide: FC<DefaultComponentProps> = ({
  children,
  className = ''
}) => {
  return (
    <section className={clsx(styles['contrast-bg-side'], className)}>
      <div className={styles['contrast-bg-side__inner']}>
        <LogoIcon light className={styles['contrast-bg-side__logo-icon']} />
        {children}
      </div>
    </section>
  )
}
