import { takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import { getAdNetworksTypesWorker } from './worker'

export function* watchAdNetworksTypes() {
  yield takeLatest(
    // @ts-ignore
    types['PARTNERS_PROGRAM/GET_AD_NETWORKS_TYPES'],
    getAdNetworksTypesWorker
  )
}
