import { call } from 'redux-saga/effects'

import { fetchDataWorker } from '../../apiRequest/saga/worker'
import { types } from '../types'

import { partnerProgramAPI } from '@/api/partnerProgramAPI'

type IPayload = { meta: { resolve: Function; reject: Function }; types: [] }

export function* calculateAwardsWorker({
  payload: {
    meta: { resolve, reject },
    types: typesArray
  }
}: {
  payload: IPayload
}) {
  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: partnerProgramAPI.calculateAwards,
    type: types['PARTNERS_PROGRAM'],
    params: typesArray
  })

  if (response?.status === 'success') {
    resolve(response)
  } else {
    reject(errors)
  }
}
