import { fetch } from './fetch'
import { api } from '@/config'

const { API_APP_URL, AD_NETWORKS_TYPES_URL, CALCULATE_AWARDS_URL } = api

const getAdNetworksTypes = params =>
  fetch({
    method: 'get',
    url: `${API_APP_URL}${AD_NETWORKS_TYPES_URL}`,
    ...params
  })

const calculateAwards = params =>
  fetch({
    method: 'post',
    url: `${API_APP_URL}${CALCULATE_AWARDS_URL}`,
    params
  })

export const partnerProgramAPI = {
  getAdNetworksTypes,
  calculateAwards
}
