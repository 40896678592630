import { call } from 'redux-saga/effects'

import { fetchDataWorker } from '../../apiRequest/saga/worker'
import { types } from '../types'

import { partnerProgramAPI } from '@/api/partnerProgramAPI'

export function* getAdNetworksTypesWorker({ payload }: { payload: any }) {
  const {
    meta: { reject, resolve }
  } = payload

  // @ts-ignore
  const { response, errors } = yield call(fetchDataWorker, {
    api: partnerProgramAPI.getAdNetworksTypes,
    type: types['PARTNERS_PROGRAM']
  })

  if (response?.status === 'success') {
    resolve(response.data)
  } else {
    reject(errors)
  }
}
