import { takeLatest } from 'redux-saga/effects'

import { types } from '../types'

import { calculateAwardsWorker } from './worker'

export function* watchCalculateAwards() {
  yield takeLatest(
    // @ts-ignore
    types['PARTNERS_PROGRAM/CALCULATE_AWARDS'],
    calculateAwardsWorker
  )
}
