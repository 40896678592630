import { all } from 'redux-saga/effects'
import { watchAdNetworksTypes } from 'src/bus/adNetworksTypes'
import { watchCalculateAwards } from 'src/bus/calculateAwards'

import { watchApiError } from '@/bus/apiRequest'
import { watchNotifications } from '@/bus/notifications'
import { watchTexts } from '@/bus/texts'
import { watchUser } from '@/bus/user'

export default function* rootSaga() {
  yield all([
    watchUser(),
    watchTexts(),
    watchNotifications(),
    watchApiError(),
    watchAdNetworksTypes(),
    watchCalculateAwards()
    /*watchMenu()*/
  ])
}
