//libraries
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { FC, useEffect, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import FooterCopyright from '@/components/FooterCopyright'
import FooterLogo from '@/components/FooterLogo'
import FooterMenu from '@/components/FooterMenu/FooterMenu'
import { FooterMenuProps } from '@/components/FooterMenu/FooterMenuProps'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import events from '@/utils/events'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Footer.module.scss'
import { routes } from '@/config'

const PARTNERS = [
  {
    id: 'yandex'
  },
  {
    id: 'vk'
  },
  {
    id: 'tiktok',
    label: false
  },
  {
    url: routes.ARDA_DIGITAL,
    id: 'arda-digital',
    label: false
  }
]

interface FooterProps extends DefaultComponentProps {
  animation?: boolean
}

export const Footer: FC<FooterProps> = ({
  className = '',
  animation = true
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const {
    t,
    i18n: { language }
  } = useTranslation('footer')

  const menu: FooterMenuProps[] = t('menu', { returnObjects: true })

  useEffect(() => {
    events.on('waypoint::8::enter', () => setAnimationStart(true))

    return () => {
      events.off('waypoint::8::enter', () => setAnimationStart(true))
    }
  }, [])

  useEffect(() => {
    if (animation) {
      return
    }
    setAnimationStart(true)
  }, [animation])

  return (
    <Waypoint
      topOffset={'20%'}
      bottomOffset={'20%'}
      onEnter={() => events.emit('waypoint::8::enter')}
    >
      <footer
        className={clsx(styles['footer'], className, {
          [styles['footer_animation-start']]: animationStart
        })}
      >
        <div className={styles['footer__content']}>
          <div className={styles['footer__column']}>
            <FooterLogo className={styles['footer__logo']} />
            <FooterCopyright />
            <div className={styles['footer__partners']}>
              {PARTNERS.map(({ id, label = true, url }) => {
                const Component = url ? Link : 'div'
                const props = url
                  ? {
                      href: url as string,
                      target: '_blank',
                      rel: 'noopener noreferrer'
                    }
                  : {}

                return (
                  // @ts-ignore
                  <Component
                    className={styles['footer__partners-link']}
                    key={id}
                    {...props}
                  >
                    <Icon
                      className={styles['footer__partners-img']}
                      name={`footer/${language}/${id}`}
                    />
                    {label && (
                      <span
                        className={styles['footer__partners-label']}
                        dangerouslySetInnerHTML={{
                          __html: t(`partners.${id}`)
                        }}
                      />
                    )}
                  </Component>
                )
              })}
            </div>
          </div>
          <div className={styles['footer__column']}>
            {menu.map(({ heading, items, id }: FooterMenuProps) => (
              <FooterMenu
                key={id}
                heading={heading}
                items={items}
                className={styles['footer__menu']}
              />
            ))}
          </div>
        </div>
      </footer>
    </Waypoint>
  )
}
